import React from 'react';
import { Divider, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { formItemClass } from '../../../../theme';
import { ProjectControl } from '../ProjectControl';
import { ProductSwitch } from './ProductSwitch';
import { projectProducts } from '../../constants';

export const ProjectDetails: React.FC = (): JSX.Element => {
  const { t } = useTranslation('project_settings');
  const { id } = useParams();
  const form = Form.useFormInstance();
  const ai_n_a = Form.useWatch('ai_n_a', form);
  const pulse = Form.useWatch('pulse', form);
  const { pulse: pulseFlag } = useFlags();
  const productsList = pulseFlag
    ? projectProducts
    : projectProducts.filter(product => product.value !== 'pulse');

  return (
    <>
      <Form.Item
        name="name"
        label={t('project_name')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('project_name')} />
      </Form.Item>
      <Divider className={formItemClass.full} />
      <b>{t('products')} </b>
      <ProductsDescription>
        {t(!id ? 'products_description' : 'available_products')}
      </ProductsDescription>
      {productsList.map((product, index) => (
        <ProductSwitch
          key={product.value}
          product={product}
          // remove the !id below once the BE allows products changes on Edit
          required={!pulse && !ai_n_a && !id}
          isLastItem={index === productsList.length - 1}
          tooltip={t(`tooltip_${product.value}`)}
          disabled={!!id}
          style={{ marginBottom: '8px' }}
        />
      ))}
      {!!id && <ProjectControl />}
    </>
  );
};

const ProductsDescription = styled.div`
  margin: 8px 0 16px 0;
`;
