import { Navigate, Outlet, Params, useParams } from 'react-router-dom';
import { useAccount } from '../../state/account';

interface RestrictContext {
  isHumanbotProject?: boolean;
  params?: Params<string>;
}

interface Props {
  shouldRestrict: (context: RestrictContext) => boolean;
  redirectTo: string;
  children?: React.ReactNode;
}

export const RestrictedPage: React.FC<Props> = ({ redirectTo, shouldRestrict, children }) => {
  const { isHumanbotProject } = useAccount();
  const params = useParams();

  const context: RestrictContext = {
    isHumanbotProject,
    params,
  };

  if (shouldRestrict(context)) return <Navigate replace to={redirectTo} />;
  return children ? <>{children}</> : <Outlet />;
};
