import React, { ReactElement, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { MenuProps } from 'antd/lib/menu';
import { isEmpty } from 'ramda';
import { DropdownButton, DropdownMenu } from '../../Dropdown';
import { TruncatedString } from '../../TruncatedString';
import { useDropdown } from '../../../hooks/useDropdown';
import { useAccount } from '../../../state/account';
import { findByBiggestId } from '../../../utils/filtering';
import { IProject, TelephonyProvider } from '../../../pages/Projects/types';
import { ProjectsService } from '../../../services/projects';
import { ACTIVE_PROJECT_NAME } from '../../../constants';
import { useRouteFlagRedirect } from '../../../hooks/useRouteFlagRedirect';

type MenuItem = MenuItemType & {
  key: string;
  value: string;
  label: JSX.Element;
  provider?: TelephonyProvider;
};

export const Projects: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();
  useRouteFlagRedirect();
  const { t } = useTranslation('header');
  const [selectedItem, setSelectedItem] = useState<ReactElement | string>();
  const { visible, toggleDropdown, dropdownRef } = useDropdown<any>(false);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const {
    activeAccountId,
    activeProjectId,
    setActiveProjectId,
    resetActiveProject,
    isNoAccount,
    setActiveProjectProvider,
  } = useAccount();
  const { data, isFetching, isFetched } = useQuery<IProject[]>(
    [ProjectsService.queryKey, activeAccountId],
    ProjectsService.get,
    { enabled: !!activeAccountId },
  );

  useEffect(() => {
    if (data?.length) {
      const options = data
        .filter(item => item.is_enabled)
        .map(item => ({
          key: item.id.toString(),
          label: (
            <TruncatedString
              isShowFullOnHover={true}
              isPointerEventsDisabled={true}
              key={item.name}
            >
              {item.name}
            </TruncatedString>
          ),
          value: item.id.toString(),
          provider: item.telephony_provider,
        }));
      setMenuItems(options);
      !options.length && resetActiveProject(true);
    } else if (!isFetching && isFetched) {
      resetActiveProject(true);
      setMenuItems([]);
    } else if (isNoAccount) {
      setSelectedItem(t('select_project'));
    }
  }, [data, isFetching, isFetched, isNoAccount]);

  useEffect(() => {
    const setProjectsBiggestId = () => {
      const lastItemById = findByBiggestId(menuItems);
      setActiveProjectId(lastItemById);
      const activeProject = menuItems[menuItems.findIndex(item => item.key === lastItemById)];
      setSelectedItem(activeProject.label || t('select_project'));
      setActiveProjectProvider(activeProject?.provider);
      localStorage.setItem(ACTIVE_PROJECT_NAME, activeProject?.label.key as string);
    };
    const setSelectedMenuItem = () => {
      if (menuItems?.length) {
        if (activeProjectId) {
          const activeProject = menuItems.find(item => item.key === activeProjectId);
          if (activeProject) {
            setSelectedItem(activeProject.label);
            localStorage.setItem(ACTIVE_PROJECT_NAME, activeProject.label.key as string);
          } else setProjectsBiggestId();
          setActiveProjectProvider(activeProject?.provider);
        } else setProjectsBiggestId();
      } else if (!isFetching && isFetched) {
        setSelectedItem(t('select_project'));
        localStorage.removeItem(ACTIVE_PROJECT_NAME);
      }
    };
    setSelectedMenuItem();
  }, [menuItems]);

  const handleProjectSelect: MenuProps['onClick'] = ({ key }) => {
    if (menuItems?.length) {
      const selectedItem = menuItems.find(item => item.key === key);
      if (selectedItem) {
        setSelectedItem(selectedItem.label);
        setActiveProjectId(selectedItem.key);
        setActiveProjectProvider(selectedItem.provider);
        localStorage.setItem(ACTIVE_PROJECT_NAME, selectedItem.label.key as string);
        if (!isEmpty(params) || /\/create\/?$/.test(pathname)) {
          const parentPath = /\/create\/?$/.test(pathname)
            ? pathname.substring(0, pathname.lastIndexOf('/create'))
            : pathname.substring(0, pathname.lastIndexOf('/')) || '/';

          navigate(parentPath || '/');
        }
      }
    }
  };

  return (
    <Dropdown
      menu={{
        items: menuItems,
        selectable: false,
        onClick: handleProjectSelect,
        ...(activeProjectId && { selectedKeys: [activeProjectId] }),
      }}
      dropdownRender={menu => (
        <DropdownMenu.Root>
          {!!menuItems.length && <DropdownMenu.Menu>{menu}</DropdownMenu.Menu>}
          <DropdownMenu.Footer single={!menuItems?.length} to={'projects'} onClick={toggleDropdown}>
            {t('view_all_projects')}
          </DropdownMenu.Footer>
        </DropdownMenu.Root>
      )}
      placement="bottomRight"
      trigger={['click']}
      open={visible}
      destroyPopupOnHide={true}
      disabled={isNoAccount}
    >
      <DropdownButton ref={dropdownRef} id="projects-button" onClick={toggleDropdown}>
        <DropdownMenu.Text>{selectedItem}</DropdownMenu.Text>
      </DropdownButton>
    </Dropdown>
  );
};
