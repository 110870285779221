import React from 'react';
import { Translation } from 'react-i18next';
import { Navigate, Outlet } from 'react-router-dom';
import { Paths } from '../../types/paths';
import { Analytics } from './index';
import { DashboardsList } from './Reports/Dashboards/DashboardsList';
import { LooksList } from './Reports/Looks/LooksList';
import { ExploreList } from './Reports/Explore/ExploreList';
import { FAReportsList } from './Reports/FAReports/FAReportsList';
import { LookerIframe } from './Reports/LookerIframe';
import { FAReportIframe } from './Reports/FAReports/FAReportIframe';
import { Configurations } from './Configurations';
import { ConfigurationsList } from './Configurations/ConfigurationsList';
import { AnalyticsWebhook } from './Configurations/AnalyticsWebhook';
import { DLPTemplateList } from './Configurations/DLPTemplate/DLPTemplateList';
import { DLPTemplateNew } from './Configurations/DLPTemplate/DLPTemplateNew';
import { DLPTemplateEdit } from './Configurations/DLPTemplate/DLPTemplateEdit';
import { DataRetentionPolicyList } from './Configurations/DataRetentionPolicy/DataRetentionPolicyList';
import { DataRetentionPolicyEdit } from './Configurations/DataRetentionPolicy/DataRetentionPolicyEdit';
import { DeleteConversations } from './Configurations/DeleteConversations';
import { RestrictedPage } from '../../components/RestrictedPage';

export const analyticRoutes = () => ({
  path: Paths.analytics(),
  element: <Analytics />,
  breadcrumb: null,
  children: [
    {
      index: true,
      element: <Navigate replace to={Paths.analyticDashboards()} />,
      breadcrumb: null,
    },
    {
      path: Paths.analyticDashboards(),
      element: <Outlet />,
      breadcrumb: () => <Translation ns={'reports'}>{t => t('dashboards')}</Translation>,
      children: [
        { index: true, element: <DashboardsList /> },
        { path: Paths.analyticDashboardView(), element: <LookerIframe /> },
      ],
    },
    {
      path: Paths.analyticLooks(),
      element: <Outlet />,
      breadcrumb: () => <Translation ns={'reports'}>{t => t('looks')}</Translation>,
      children: [
        { index: true, element: <LooksList /> },
        { path: Paths.analyticLookView(), element: <LookerIframe /> },
      ],
    },
    {
      path: Paths.analyticExplore(),
      element: <Outlet />,
      breadcrumb: () => <Translation ns={'reports'}>{t => t('explore')}</Translation>,
      children: [
        { index: true, element: <ExploreList /> },
        { path: Paths.analyticExploreView(), element: <LookerIframe /> },
      ],
    },
    {
      path: Paths.analyticFAReports(),
      element: <Outlet />,
      breadcrumb: () => <Translation ns={'reports'}>{t => t('reports')}</Translation>,
      children: [
        { index: true, element: <FAReportsList /> },
        { path: Paths.analyticFAReportView(), element: <FAReportIframe /> },
      ],
    },
    {
      path: Paths.analyticConfigurations(),
      element: <Configurations />,
      breadcrumb: () => <Translation ns={'ai'}>{t => t('configurations')}</Translation>,
      children: [
        {
          index: true,
          element: <ConfigurationsList />,
        },
        {
          path: Paths.analyticWebhook(),
          element: (
            <RestrictedPage
              shouldRestrict={({ isHumanbotProject }) => !!isHumanbotProject}
              redirectTo={Paths.analyticConfigurations()}
            >
              <AnalyticsWebhook />
            </RestrictedPage>
          ),
          breadcrumb: () => <Translation ns={'ai'}>{t => t('ext_webhook_req')}</Translation>,
        },
        {
          path: Paths.analyticDLP(),
          element: <Outlet />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('dlp_template')}</Translation>,
          children: [
            { index: true, element: <DLPTemplateList /> },
            {
              path: Paths.analyticDLPCreate(),
              element: <DLPTemplateNew />,
              breadcrumb: () => <Translation>{t => t('create')}</Translation>,
            },
            {
              path: Paths.analyticDLPEdit(),
              element: <DLPTemplateEdit />,
              breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
            },
          ],
        },
        {
          path: Paths.analyticDataRetentionPolicy(),
          element: <Outlet />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('data_retention_policy')}</Translation>,
          children: [
            { index: true, element: <DataRetentionPolicyList /> },
            {
              path: Paths.analyticDataRetentionPolicyEdit(),
              element: <DataRetentionPolicyEdit />,
              breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
            },
          ],
        },
        {
          path: Paths.analyticsDeleteConversations(),
          element: (
            <RestrictedPage
              shouldRestrict={({ isHumanbotProject }) => !!isHumanbotProject}
              redirectTo={Paths.analyticConfigurations()}
            >
              <DeleteConversations />
            </RestrictedPage>
          ),
          breadcrumb: () => <Translation ns={'ai'}>{t => t('delete_conversations')}</Translation>,
        },
      ],
    },
  ],
});
