import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { Content } from '../../../../components/Content';
import { StyledCard, CardInfo } from '../../AI.styles';
import { Paths } from '../../../../types/paths';
import { ViewLink } from '../../../../components/ViewLink';
import { useAccount } from '../../../../state/account';
import useProjectLoading from '../../../../hooks/useProjectLoading';
import { CenteredSpinner } from '../../../../components/Spinner';

export const RoutingList: React.FC = () => {
  const { t } = useTranslation('ai');
  const { isHumanbotProject } = useAccount();
  const isProjectLoading = useProjectLoading();

  return (
    <Content imgBg={false}>
      <Row gutter={16}>
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard title={t('routers')} bordered={false}>
            <CardInfo>{t('routers_description')}</CardInfo>
            <ViewLink to={Paths.aiRouters()} />
          </StyledCard>
        </Col>
        {!isHumanbotProject && (
          <>
            <Col xs={24} sm={12} lg={8} xxl={6}>
              <StyledCard title={t('external_endpoints')} bordered={false}>
                <CardInfo>{t('external_endpoints_description')}</CardInfo>
                <ViewLink to={Paths.aiExternalEndpoints()} />
              </StyledCard>
            </Col>
            <Col xs={24} sm={12} lg={8} xxl={6}>
              <StyledCard title={t('teams')} bordered={false}>
                <CardInfo>{t('teams_desc')}</CardInfo>
                <ViewLink to={Paths.aiTeams()} />
              </StyledCard>
            </Col>
          </>
        )}
      </Row>
      <CenteredSpinner isOverlay={true} isVisible={isProjectLoading} />
    </Content>
  );
};
