import React from 'react';
import { Outlet } from 'react-router-dom';
import { AnalyticsMenu } from './AnalyticsMenu';
import useStateLoading from '../../hooks/useStateLoading';
import { NoProject } from '../../components/NoProject';
import { CenteredSpinner } from '../../components/Spinner';

export const Analytics: React.FC = () => {
  const { isStateLoading, isProject } = useStateLoading();

  if (isStateLoading) return <CenteredSpinner isOverlay={true} isVisible={isStateLoading} />;
  if (!isProject) return <NoProject />;
  return (
    <>
      <AnalyticsMenu />
      <Outlet />
    </>
  );
};
