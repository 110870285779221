import React, { useState } from 'react';
import { Form, FormInstance, Input, Select } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formItemClass } from '../../../../../theme';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { ChannelType, ChannelTypes, IChannelsValues } from '../types';
import { validateMessages } from '../../../../../utils/validation';
import { ChannelIdentifierFields, ChannelTypesLabel } from '../../../../../constants';
import { DialPlansSelector } from '../../../../../components/DialPlansSelector';
import { IncomingPhoneNumberSelector } from '../../../../../components/IncomingPhoneNumberSelector';
import { useAccount } from '../../../../../state/account';

interface Props {
  initialValues: IChannelsValues;
  onSubmit: (values: IChannelsValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
  isChannel?: boolean;
  isEditing?: boolean;
}

export const ChannelForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  submitButton,
  isChannel,
  isEditing,
}) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const { allChannels } = useFlags();
  const { isHumanbotProject } = useAccount();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);
  const [channelType, setChannelType] = useState<ChannelType | undefined>(
    initialValues.channel_type,
  );

  const availableChannelTypes = allChannels
    ? ChannelTypesLabel
    : ChannelTypesLabel.filter(channel =>
        ['web', 'voice', 'facebook', 'sms'].includes(channel.value),
      );

  const getIdentifierDetails = (channel: ChannelType | undefined) => {
    const field = ChannelIdentifierFields.find(field => field.channel === channel);
    return {
      placeholder: field ? field.placeholder : '',
      tooltip: field ? field.tooltip : '',
    };
  };
  const identifier = getIdentifierDetails(channelType);

  const handleChannelTypeChange = (value: ChannelType) => {
    setChannelType(value);
    form.setFieldValue('phone_number', undefined);
  };

  const handleCancel = () => {
    const newInitialValues = {
      ...initialValues,
    };
    if (!isChannel && !isEditing) delete newInitialValues.phone_number;
    if (!equals(newInitialValues, form.getFieldsValue())) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  const onFinish = () => {
    const formData = form.getFieldsValue();
    onSubmit(formData, form.setFields);
  };

  return (
    <Form
      form={form}
      validateMessages={validateMessages}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        name="channel_type"
        label={t('channel_type')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Select
          options={availableChannelTypes}
          placeholder={t('select_channel_type')}
          onChange={handleChannelTypeChange}
          disabled={isChannel}
        />
      </Form.Item>
      {channelType ? (
        channelType === ChannelTypes.web ||
        channelType === ChannelTypes.facebook ||
        isHumanbotProject ? (
          <Form.Item
            name="phone_number"
            label={t('identifier')}
            tooltip={t(identifier.tooltip)}
            className={formItemClass.base}
            rules={[{ required: true }]}
          >
            <Input placeholder={t(identifier.placeholder)} />
          </Form.Item>
        ) : (
          <Form.Item
            name="phone_number"
            label={t('identifier')}
            tooltip={t(identifier.tooltip)}
            className={formItemClass.base}
            rules={[{ required: true }]}
          >
            <IncomingPhoneNumberSelector
              placeholder={t(identifier.placeholder)}
              channelType={channelType}
            />
          </Form.Item>
        )
      ) : null}
      <Form.Item
        name="dial_plan_id"
        label={t('automation_profile')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <DialPlansSelector placeholder={t('select_automation_profile')} />
      </Form.Item>
      <FixedBottomBar>
        <FormActions>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActions>
      </FixedBottomBar>
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
    </Form>
  );
};
