/**
 * Create a route that can be parsed using a typed-obj as param
 *
 * @example
 * Example using typed params
 * ```js
 * const userUpdate = route<'id'>('/user/:id')
 * userUpdate({ id: 1 }) // /user/1
 * ```
 */

export function route<T extends Record<string, any> = any>(
  path: string,
  options: { flag?: string | null } = {},
) {
  const parse = function parse(params: T = {} as T, query?: Record<string, any>): string {
    const split = path.match(/[^/]+/g); // Splits the path into segments

    // Map over each segment in the path
    const parsed = split
      ?.map(str => {
        const key = str.replace(':', ''); // Remove ':' if it exists
        let paramValue: string | number | boolean = '';

        if (key in params) {
          paramValue = params[key];
        } else {
          paramValue = str; // Otherwise, use the segment as is
        }

        return paramValue;
      })
      .join('/'); // Join segments with '/'

    // Construct the final URL path including query parameters
    return `/${parsed ?? ''}${searchStringify(query)}`;
  };
  Object.assign(parse, options);
  return parse as typeof parse & { flag?: string | null };
}

/**
 * Converts an object into a query string.
 *
 * @example
 * searchStringify({ foo: 'bar', baz: 'qux' });
 * // returns '?foo=bar&baz=qux'
 */
export function searchStringify(query?: Record<any, any>) {
  const qs = new URLSearchParams(query).toString();
  return qs.length ? `?${qs}` : '';
}
