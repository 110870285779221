import React, { useEffect, useState } from 'react';
import { Col, Flex, Form, Input, Row, Select, Switch, TimePicker, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { TimeState } from '../../../../../../constants';
import { ITeamsValues } from '../../types';

const { Text } = Typography;

type DisabledTimes = { [key: number]: boolean | undefined };
type DisabledState = { [key: number]: boolean };

interface Props {
  initialValues: ITeamsValues['time_schedules_attributes'];
}

export const TimeSchedule: React.FC<Props> = ({ initialValues }) => {
  const { t } = useTranslation('ai');
  const { setFields, getFieldValue, setFieldValue, resetFields } =
    Form.useFormInstance<ITeamsValues>();
  const [disabledTimes, setDisabledTimes] = useState<DisabledTimes>({});
  const [disabledState, setDisabledState] = useState<DisabledState>({});

  useEffect(() => {
    const initialDisabledState: DisabledState = {};
    const initialDisabledTimes: DisabledTimes = {};

    initialValues.forEach((schedule, index) => {
      initialDisabledState[index] = schedule.closed;
      initialDisabledTimes[index] = schedule.state;

      if (schedule.closed) {
        setFieldValue(['time_schedules_attributes', index, 'state'], '-');
        setFieldValue(['time_schedules_attributes', index, 'start_time'], '-');
        setFieldValue(['time_schedules_attributes', index, 'end_time'], '-');
      }
    });

    setDisabledState(initialDisabledState);
    setDisabledTimes(initialDisabledTimes);
  }, []);

  const handleTimeValidation = (key: number, field: 'start_time' | 'end_time') => {
    const startTime = getFieldValue(['time_schedules_attributes', key, 'start_time']);
    const endTime = getFieldValue(['time_schedules_attributes', key, 'end_time']);

    if (!startTime || !endTime) {
      return Promise.resolve();
    }

    const start = dayjs(startTime, 'h:mm A');
    const end = dayjs(endTime, 'h:mm A');
    if (field === 'start_time' && start.isAfter(end)) {
      return Promise.reject(t('error_start_time'));
    }
    if (field === 'end_time' && end.isBefore(start)) {
      return Promise.reject(t('error_end_time'));
    }

    const relatedField = field === 'start_time' ? 'end_time' : 'start_time';
    setTimeout(() => {
      setFields([{ name: ['time_schedules_attributes', key, relatedField], errors: [] }]);
    }, 0);

    return Promise.resolve();
  };

  const handleClosedChange = (checked: boolean, index: number) => {
    setDisabledState(prev => ({ ...prev, [index]: checked }));
    if (checked) {
      resetFields([
        ['time_schedules_attributes', index, 'start_time'],
        ['time_schedules_attributes', index, 'end_time'],
      ]);
      setFieldValue(['time_schedules_attributes', index, 'state'], '-');
      setFieldValue(['time_schedules_attributes', index, 'start_time'], '-');
      setFieldValue(['time_schedules_attributes', index, 'end_time'], '-');
    } else {
      setDisabledTimes(prev => ({ ...prev, [index]: false }));
      setFieldValue(
        ['time_schedules_attributes', index, 'start_time'],
        initialValues[index].start_time,
      );
      setFieldValue(
        ['time_schedules_attributes', index, 'end_time'],
        initialValues[index].end_time,
      );
      setFieldValue(['time_schedules_attributes', index, 'state'], false);
    }
  };

  const handleStateChange = (value: boolean, index: number) => {
    if (value) {
      resetFields([
        ['time_schedules_attributes', index, 'start_time'],
        ['time_schedules_attributes', index, 'end_time'],
      ]);
      setFieldValue(
        ['time_schedules_attributes', index, 'start_time'],
        dayjs('12:00 AM', 'h:mm A'),
      );
      setFieldValue(['time_schedules_attributes', index, 'end_time'], dayjs('11:59 PM', 'h:mm A'));
      setDisabledTimes(prev => ({ ...prev, [index]: true }));
    } else {
      setDisabledTimes(prev => ({ ...prev, [index]: false }));
    }
  };

  return (
    <Form.List name="time_schedules_attributes">
      {fields => (
        <Flex vertical>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
              xl: 40,
            }}
            style={{ marginBottom: 16 }}
          >
            <Col span={3}>
              <Text strong>{t('day')}</Text>
            </Col>
            <Col span={3}>
              <Text strong>{t('closed')}</Text>
            </Col>
            <Col span={6}>
              <Text strong>{t('state')}</Text>
            </Col>
            <Col span={6}>
              <Text strong>{t('start_time')}</Text>
            </Col>
            <Col span={6}>
              <Text strong>{t('end_time')}</Text>
            </Col>
          </Row>
          {fields.map(({ key, name }) => (
            <Row
              key={key}
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
                xl: 40,
              }}
            >
              <Col span={3} style={{ paddingRight: 0 }}>
                <Form.Item name={[name, 'id']} noStyle>
                  <Input hidden />
                </Form.Item>
                <Form.Item name={[name, 'day']}>
                  <Text>{t(initialValues?.[name]?.day)}</Text>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name={[name, 'closed']} valuePropName="checked">
                  <Switch onChange={checked => handleClosedChange(checked, key)} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name={[name, 'state']}>
                  {getFieldValue(['time_schedules_attributes', key, 'closed']) ? (
                    <Input disabled />
                  ) : (
                    <Select
                      options={TimeState}
                      disabled={disabledState[key]}
                      style={{ width: '100%' }}
                      onChange={value => handleStateChange(value, key)}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={[name, 'start_time']}
                  rules={[
                    { required: true },
                    { validator: () => handleTimeValidation(key, 'start_time') },
                  ]}
                >
                  {getFieldValue(['time_schedules_attributes', key, 'closed']) ? (
                    <Input disabled />
                  ) : (
                    <TimePicker
                      allowClear={false}
                      use12Hours
                      format="h:mm A"
                      minuteStep={5}
                      disabled={disabledTimes[key]}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={[name, 'end_time']}
                  rules={[
                    { required: true },
                    { validator: () => handleTimeValidation(key, 'end_time') },
                  ]}
                >
                  {getFieldValue(['time_schedules_attributes', key, 'closed']) ? (
                    <Input disabled />
                  ) : (
                    <TimePicker
                      allowClear={false}
                      use12Hours
                      format="h:mm A"
                      minuteStep={5}
                      disabled={disabledTimes[key]}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          ))}
        </Flex>
      )}
    </Form.List>
  );
};
