import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Location } from 'history';
import styled from '@emotion/styled';
import { Content } from '../../../../components/Content';
import { ReportType } from '../types';
import { BreadCrumbsText, UppercaseLink } from '../../Analyics.styles';
import { useIntegrations } from '../../../../state/integrations';
import { CenteredSpinner } from '../../../../components/Spinner';
import { getEmbedType, initializeEmbedSDK } from '../../../../utils/looker';

interface ILocation extends Location {
  state: { name: string; type: Exclude<ReportType, 'fAReports'>; componentId: number };
}

export const LookerIframe: React.FC = () => {
  const { t } = useTranslation('reports');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation() as ILocation;
  const { analytics: { url, externalId } = {} } = useIntegrations();
  const { type, name, componentId } = location.state || {};
  const { id } = useParams();

  useEffect(() => {
    if (!type) navigate('..');
  }, [type]);

  useEffect(() => {
    if (externalId && componentId && url && id) {
      setIsLoading(true);
      const container = document.getElementById('dashboard-container');
      if (container?.firstChild) container.removeChild(container.firstChild);

      initializeEmbedSDK(externalId, componentId, url);

      if (type && container) {
        const embedType = getEmbedType(type, id);
        if (embedType) {
          embedType()
            .withAllowAttr('fullscreen')
            .appendTo(container)
            .build()
            .connect()
            .then(() => setIsLoading(false))
            .catch(error => console.error(`Error embedding ${type}: `, error));
        }
      }
    }
  }, [id, externalId, componentId]);

  return (
    <Content imgBg={false} header={false}>
      <UppercaseLink to={`/analytics/${type}`}> {t(type)} | </UppercaseLink>
      <BreadCrumbsText>{` ${name}`} </BreadCrumbsText>
      <div style={{ position: 'relative' }}>
        <DashboardContainer id="dashboard-container" />
        <CenteredSpinner isOverlay={true} isVisible={isLoading} />
      </div>
    </Content>
  );
};

const DashboardContainer = styled.div`
  width: 100%;
  height: calc(100vh - 190px);
  iframe {
    padding-top: 20px;
    height: 100%;
  }
`;
