import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { formItemClass } from '../../../../../../theme';
import { LanguageSelect } from '../LanguageSelect';
import { AdditionalLanguages } from '../AdditionalLanguages';
import { ILang } from '../../../../../../hooks/useLanguages';

export const HumanbotSettings = ({ languages }: { languages?: ILang[] }) => {
  const { t } = useTranslation('project_settings');
  const { id } = useParams();

  return (
    <>
      {!id && (
        <Form.Item
          name={['ai_n_a_attributes', 'twilio_phone_number']}
          label={t('phone_number')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder="###-###-####" />
        </Form.Item>
      )}
      <LanguageSelect languages={languages} disabled={!!id} required={true} />
      <AdditionalLanguages languages={languages} />
    </>
  );
};
