import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { formItemClass } from '../../../../../theme';
import { filterSelectOptions } from '../../../../../utils/filtering';
import { ILang } from '../../../../../hooks/useLanguages';

interface Props {
  languages?: ILang[];
  disabled: boolean;
  required: boolean;
}

export const LanguageSelect = ({ languages, disabled, required }: Props) => {
  const { t } = useTranslation('project_settings');
  const languagesOptions = useMemo(
    () =>
      languages?.map(lng => ({ value: lng.id.toString(), label: `${lng.name} (${lng.locale})` })),
    [languages],
  );

  return (
    <Form.Item
      name="default_language_id"
      label={t('default_language')}
      className={formItemClass.base}
      rules={[{ required }]}
    >
      <Select
        options={languagesOptions}
        placeholder={t('default_language')}
        disabled={disabled}
        showSearch
        filterOption={filterSelectOptions}
      />
    </Form.Item>
  );
};
