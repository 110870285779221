import React from 'react';
import { Form, FormInstance } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ProjectDetails } from './ProjectDetails';
import { WizardForm } from '../../../components/WizardForm';
import { IProjectsValues } from '../types';
import { ProjectSettings } from './ProjectSettings';
import { IFormContext, useFormContext } from '../../../state/form';

interface IProjectForm {
  initialValues?: Partial<IProjectsValues>;
  onSubmit: (
    values: IProjectsValues,
    setFields: FormInstance['setFields'],
    setErrorFields: IFormContext['setErrorFields'],
  ) => void;
  submitButton: React.ReactElement;
  isEdit?: boolean;
  page?: number;
}
export const ProjectsForm: React.FC<IProjectForm> = ({
  initialValues,
  onSubmit,
  submitButton,
  isEdit,
  page,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('project_settings');
  const ai_n_a = Form.useWatch('ai_n_a', { form, preserve: true });
  const { setErrorFields } = useFormContext();

  const onFinish = () => {
    const formData = form.getFieldsValue(true);
    onSubmit(formData, form.setFields, setErrorFields);
  };

  const steps = [
    { title: t('project_details'), content: <ProjectDetails /> },
    ...(isEdit && ai_n_a ? [{ title: t('project_settings'), content: <ProjectSettings /> }] : []),
    ...(!isEdit ? [{ title: t('project_settings'), content: <ProjectSettings /> }] : []),
  ];

  return (
    <WizardFormWrapper>
      <WizardForm<Partial<IProjectsValues>>
        form={form}
        steps={steps}
        initialValues={initialValues}
        isEdit={isEdit}
        onFinish={onFinish}
        submitButton={submitButton}
        page={page}
      />
    </WizardFormWrapper>
  );
};

const WizardFormWrapper = styled.div`
  .ant-space-item ~ .ant-space-item:has(> div > form) {
    width: 100%;
  }
  form {
    max-width: 792px;
  }
`;
