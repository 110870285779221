import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { LanguagesSearch } from '../../LanguagesSearch';
import { ILang } from '../../../../../hooks/useLanguages';

export const AdditionalLanguages = ({ languages }: { languages?: ILang[] }) => {
  const { t } = useTranslation('project_settings');

  return (
    <Form.Item
      label={t('additional_languages')}
      name="project_languages_attributes"
      style={{ marginBottom: 0 }}
    >
      <LanguagesSearch languages={languages} placeholder={t('search_language')} />
    </Form.Item>
  );
};
