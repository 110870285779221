import React from 'react';
import { Outlet } from 'react-router-dom';
import useStateLoading from '../../hooks/useStateLoading';
import { NoProject } from '../../components/NoProject';
import { KnowledgeMenu } from './KnowledgeMenu';
import { IntroModal } from './IntroModal';
import { CenteredSpinner } from '../../components/Spinner';

export const Knowledge: React.FC = () => {
  const { isStateLoading, isProject } = useStateLoading();

  if (isStateLoading) return <CenteredSpinner isOverlay={true} isVisible={isStateLoading} />;
  if (!isProject) return <NoProject />;
  return (
    <>
      <KnowledgeMenu />
      <Outlet />
      <IntroModal />
    </>
  );
};
