import { LookerEmbedSDK } from '@looker/embed-sdk';
import { LookerSession } from '../services/looker';
import { ReportType } from '../pages/Analytics/Reports/types';

export const initializeEmbedSDK = (externalId: string, componentId: number, url: string) => {
  LookerEmbedSDK.initCookieless(
    'sabioltd.cloud.looker.com',
    async () =>
      LookerSession.acquireEmbedSession({
        projectId: externalId,
        componentId,
        analyticsUrl: url,
      }),
    async () =>
      LookerSession.generateEmbedTokens({
        projectId: externalId,
        componentId,
        analyticsUrl: url,
      }),
  );
};

export const getEmbedType = (type: Exclude<ReportType, 'fAReports'>, id: string) =>
  ({
    dashboards: () => LookerEmbedSDK.createDashboardWithId(id),
    looks: () => LookerEmbedSDK.createLookWithId(Number(id)),
    explore: () => LookerEmbedSDK.createExploreWithId(decodeURIComponent(id)),
  }[type]);
