import { useMemo } from 'react';
import { useAccount } from '../../state/account';

export default function useProjectLoading() {
  const { isHumanbotProject, ldContext } = useAccount();

  return useMemo(
    () => isHumanbotProject === undefined || ldContext?.project === undefined,
    [isHumanbotProject, ldContext?.project],
  );
}
