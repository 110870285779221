import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Form, Input, FormInstance, Switch, Divider, Typography, Select } from 'antd';
import { equals } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../../../../theme';
import { FixedBottomBar } from '../../../../../components/FixedBottomBar';
import { FormActions } from '../../../../../components/FormActions';
import { ActionButton } from '../../../../../components/ActionButton';
import { DiscardChangesModal } from '../../../../../components/DiscardChangesModal';
import { useModal } from '../../../../../hooks/useModal';
import { ITeamsValues, ITimeSchedule } from '../types';
import { validateMessages } from '../../../../../utils/validation';
import { Space } from '../../../../../components/Space';
import { TitleDesc } from '../../../AI.styles';
import { TimezoneByCity } from '../../../../../constants';
import { snakeToCapitalCase } from '../../../../../utils/string';
import { TimeSchedule } from './TimeSchedule';

const { Title } = Typography;

interface Props {
  initialValues: ITeamsValues;
  onSubmit: (values: ITeamsValues, setFields: FormInstance['setFields']) => void;
  submitButton: React.ReactElement;
}

export const TeamsForm: React.FC<Props> = ({ initialValues, onSubmit, submitButton }) => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { visible, openModal, closeModal } = useModal(false);

  const handleCancel = () => {
    if (!equals(initialValues, form.getFieldsValue())) {
      openModal();
    } else {
      navigate(-1);
    }
  };

  const onFinish = () => {
    const formData = form.getFieldsValue();
    formData.time_schedules_attributes = formData.time_schedules_attributes.map(
      (schedule: ITimeSchedule) => {
        const formatTime = (time: Dayjs): string => {
          return dayjs(time).format('HH:mm') === 'Invalid Date'
            ? '00:00'
            : dayjs(time).format('HH:mm');
        };

        return {
          ...(schedule.id && { id: schedule.id }),
          day: snakeToCapitalCase(schedule.day),
          start_time: formatTime(schedule.start_time),
          end_time: formatTime(schedule.end_time),
          closed: schedule.closed,
        };
      },
    );
    onSubmit(formData, form.setFields);
  };

  return (
    <Form
      form={form}
      validateMessages={validateMessages}
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Space>
        <Form.Item
          name="name"
          label={t('name')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('enter_name')} />
        </Form.Item>
        <Form.Item
          name="is_default"
          tooltip={t('teams_default_tooltip')}
          valuePropName="checked"
          label={t('default')}
        >
          <Switch />
        </Form.Item>
      </Space>
      <Space>
        <Form.Item
          name="workflow_sid"
          label={t('workflow_sid')}
          className={formItemClass.base}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('enter_workflow_sid')} />
        </Form.Item>
        <Form.Item name="skill" label={t('skill')} className={formItemClass.base}>
          <Input placeholder={t('enter_skill')} />
        </Form.Item>
      </Space>
      <Form.Item
        name="timezone"
        label={t('timezone')}
        className={formItemClass.base}
        rules={[{ required: true }]}
        valuePropName="value"
      >
        <Select showSearch placeholder={t('select_timezone')} options={TimezoneByCity} />
      </Form.Item>
      <Divider className={formItemClass.full} />
      <Title level={5} type="secondary">
        {t('call_schedule')}
      </Title>
      <TitleDesc>{t('call_schedule_desc')}</TitleDesc>
      <TimeSchedule initialValues={initialValues.time_schedules_attributes} />
      <FixedBottomBar>
        <FormActions>
          <ActionButton type="cancel" key="cancel" onClick={handleCancel} />
          {submitButton}
        </FormActions>
      </FixedBottomBar>
      <DiscardChangesModal visible={visible} closeModal={closeModal} />
    </Form>
  );
};
