import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams } from 'react-router-dom';
import { Divider, Form, Input, Radio, Select, Switch } from 'antd';
import { ILang } from '../../../../../../hooks/useLanguages';
import { formItemClass } from '../../../../../../theme';
import { GoogleCloudProjectInput } from '../../../../../../components/GoogleCloudProjectInput';
import { LanguageSelect } from '../LanguageSelect';
import { AdditionalLanguages } from '../AdditionalLanguages';
import { Space } from '../../../../../../components/Space';
import { PasswordInput } from '../../../../../../components/PasswordInput';

export const TwilioSettings = ({ languages }: { languages?: ILang[] }) => {
  const { t } = useTranslation('project_settings');
  const { id } = useParams();
  const { knowledgeWorkflow } = useFlags();

  return (
    <>
      {!id && (
        <>
          <Form.Item name="nlu_provider" label={t('nlu_provider')} className={formItemClass.base}>
            <Select
              placeholder={t('nlu_provider')}
              options={[{ value: 'google', label: 'Google DialogFlow' }]}
            />
          </Form.Item>
          <GoogleCloudProjectInput isProjectCreation />
          <Form.Item
            name="platform_edition"
            label={t('platform_edition')}
            className={formItemClass.base}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="es">ES</Radio.Button>
              <Radio.Button value="cx" disabled>
                CX
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Divider />
          <Form.Item
            name="voice_provider"
            label={t('chat_voice_provider')}
            className={formItemClass.base}
          >
            <Select
              placeholder={t('chat_voice_provider')}
              options={[{ value: 'twilio', label: 'Twilio' }]}
            />
          </Form.Item>
          <Space>
            <Form.Item
              name={['ai_n_a_attributes', 'twilio_account_sid']}
              label={t('twilio_account_sid')}
              className={formItemClass.base}
              rules={[{ required: true }]}
              tooltip={t('twilio_account_sid_tooltip')}
            >
              <Input placeholder="ACxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx" />
            </Form.Item>
            <Form.Item
              name={['ai_n_a_attributes', 'twilio_auth_token']}
              label={t('twilio_auth_token')}
              className={formItemClass.base}
              rules={[{ required: true }]}
              tooltip={t('twilio_auth_token_tooltip')}
            >
              <PasswordInput placeholder="*******************" />
            </Form.Item>
          </Space>
          <Form.Item
            name={['ai_n_a_attributes', 'twilio_phone_number']}
            label={t('twilio_phone_number')}
            className={formItemClass.base}
            rules={[{ required: true }]}
          >
            <Input placeholder="###-###-####" />
          </Form.Item>
          <Divider />
        </>
      )}
      <Space size={40}>
        <LanguageSelect languages={languages} disabled={!!id} required={true} />
        {knowledgeWorkflow && (
          <Form.Item
            name={['knowledge_v2_attributes', 'is_approval_flow_enabled']}
            label={t('workflow')}
            valuePropName="checked"
            tooltip={t('tooltip_workflow')}
          >
            <Switch disabled={!!id} />
          </Form.Item>
        )}
      </Space>
      <AdditionalLanguages languages={languages} />
    </>
  );
};
