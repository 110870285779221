import React from 'react';
import { Form, Select, Divider } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formItemClass } from '../../../../../theme';
import useLanguages from '../../../../../hooks/useLanguages';
import { HumanbotSettings } from './HumanbotSettings';
import { TwilioSettings } from './TwilioSettings';

export const AIAutomationSettings: React.FC = (): JSX.Element => {
  const { t } = useTranslation('project_settings');
  const { id } = useParams();
  const { languages } = useLanguages();
  const { setFieldValue } = Form.useFormInstance();
  const { humanbot } = useFlags();

  const telephonyProviders = [
    { label: 'Twilio', value: 'twilio' },
    ...(humanbot ? [{ label: 'Humanbot', value: 'humanbot' }] : []),
  ];

  const handleTelProviderChange = () => {
    setFieldValue(['ai_n_a_attributes', 'twilio_phone_number'], undefined);
  };

  return (
    <>
      <Divider style={{ margin: '0 0 8px' }} />
      <Form.Item
        name="telephony_provider"
        label={t('telephony_provider')}
        className={formItemClass.base}
        rules={[{ required: true }]}
      >
        <Select options={telephonyProviders} disabled={!!id} onChange={handleTelProviderChange} />
      </Form.Item>
      <Divider style={{ margin: '0 0 8px' }} />
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.telephony_provider !== currentValues.telephony_provider
        }
      >
        {({ getFieldValue }) => {
          return (
            <>
              {getFieldValue('telephony_provider') === 'humanbot' ? (
                <HumanbotSettings languages={languages} />
              ) : (
                <TwilioSettings languages={languages} />
              )}
            </>
          );
        }}
      </Form.Item>
    </>
  );
};
