import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { ACTIVE_ACCOUNT_ID, ACTIVE_PROJECT_ID } from '../constants';
import useUpdateLDContext, { ILDContext } from '../hooks/useUpdateLDContext';
import { useAuth } from './auth';
import { TelephonyProvider } from '../pages/Projects/types';

export interface IAccountContext {
  activeAccountId?: string;
  setActiveAccountId: (id: string, projectId?: string) => void;
  activeProjectId?: string;
  setActiveProjectId: (value: string | undefined) => void;
  setActiveProjectProvider: (value: TelephonyProvider | undefined) => void;
  resetActiveProject: (value: boolean) => void;
  isProjectDataFetched: boolean;
  ldContext?: ILDContext;
  setLdContext: (value: ILDContext) => void;
  isNoAccount: boolean;
  isHumanbotProject?: boolean;
}

export const AccountContext = React.createContext<IAccountContext>({} as IAccountContext);

export const AccountProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { authenticated } = useAuth();
  const [activeAccountId, _setActiveAccountId] = useState<IAccountContext['activeAccountId']>();
  const [activeProjectId, setActiveProjectId] = useState<IAccountContext['activeProjectId']>();
  const [activeProjectProvider, setActiveProjectProvider] = useState<TelephonyProvider>();
  // Storing the up-to-date version of the Launchdarkly context
  const [ldContext, setLdContext] = useState<ILDContext>();

  const isProjectDataFetched = useMemo(() => {
    return typeof activeProjectId !== 'undefined' && typeof activeAccountId !== 'undefined';
  }, [activeAccountId, activeProjectId]);

  useUpdateLDContext({ activeAccountId, activeProjectId, isProjectDataFetched, setLdContext });

  useEffect(() => {
    if (!authenticated) {
      _setActiveAccountId(undefined);
      setActiveProjectId(undefined);
      setActiveProjectProvider(undefined);
    }
  }, [authenticated]);

  useEffect(() => {
    activeAccountId &&
      localStorage.getItem(ACTIVE_ACCOUNT_ID) !== activeAccountId &&
      localStorage.setItem(ACTIVE_ACCOUNT_ID, activeAccountId);
  }, [activeAccountId]);

  useEffect(() => {
    activeProjectId &&
      localStorage.getItem(ACTIVE_PROJECT_ID) !== activeProjectId &&
      localStorage.setItem(ACTIVE_PROJECT_ID, activeProjectId);
  }, [activeProjectId]);

  const setActiveAccountId = useCallback((id: string, projectId?: string) => {
    _setActiveAccountId(id);
    typeof projectId !== 'undefined' ? setActiveProjectId(projectId) : resetActiveProject();
  }, []);

  const resetActiveProject = (isNoProjects?: boolean) => {
    isNoProjects ? setActiveProjectId('') : setActiveProjectId(undefined);
    localStorage.removeItem(ACTIVE_PROJECT_ID);
    setActiveProjectProvider(undefined);
  };

  const value = useMemo(
    () => ({
      activeAccountId,
      setActiveAccountId,
      activeProjectId,
      setActiveProjectId,
      resetActiveProject,
      setActiveProjectProvider,
      isProjectDataFetched,
      ldContext,
      setLdContext,
      isNoAccount: activeAccountId === '',
      isHumanbotProject:
        activeProjectProvider === undefined ? undefined : activeProjectProvider === 'humanbot',
    }),
    [activeAccountId, activeProjectId, ldContext, activeProjectProvider],
  );

  return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>;
};

export function useAccount(): IAccountContext {
  return useContext(AccountContext);
}
