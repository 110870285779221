import { SelectProps } from 'antd';

export const filterSelectOptions: SelectProps['filterOption'] = (inputValue, option) => {
  if (typeof option?.label === 'string') {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  }
  return false;
};

export interface IdValue {
  value: string;
}

export const findByBiggestId = (array: IdValue[]): string => {
  return array
    .reduce((prev: IdValue, current: IdValue) =>
      Number(prev.value) > Number(current.value) ? prev : current,
    )
    .value.toString();
};
