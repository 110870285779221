import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { DataTable } from '../../../components/DataTable';
import { ILookerItem, ReportType } from '../Reports/types';
import { DeleteConfirmation } from '../../../components/DeleteConfirmation';
import { snakeToCapitalCase } from '../../../utils/string';

interface Props {
  reports: ILookerItem[];
  loading: boolean;
  handleRemove?: (id: string | number) => void;
  type: ReportType;
}

export const ReportsTable: React.FC<Props> = React.memo(props => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { reports, loading, handleRemove, type } = props;
  const { pathname } = location;

  const onCellHandler = (record: ILookerItem) => {
    const { name, id, component } = record;
    return {
      onClick: () =>
        navigate(`${pathname}/${id}`, {
          state: {
            name,
            type,
            ...(type !== 'fAReports' && { componentId: component.id }),
          },
        }),
    };
  };

  const columns: ColumnProps<ILookerItem>[] = [
    {
      title: t('name'),
      dataIndex: 'name',
      onCell: onCellHandler,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    ...(type !== 'fAReports'
      ? [
          {
            title: t('component', { ns: 'reports' }),
            dataIndex: ['component', 'name'],
            render: (value: string) => snakeToCapitalCase(value),
            onCell: onCellHandler,
            sorter: (a: ILookerItem, b: ILookerItem) =>
              a.component.name.localeCompare(b.component.name),
          },
        ]
      : []),
    ...(handleRemove
      ? [
          {
            key: 'actions',
            width: '3%',
            render: (record: ILookerItem) => (
              <DeleteConfirmation id={record.url} onActionDelete={handleRemove} />
            ),
            className: 'actions',
          },
        ]
      : []),
  ];

  return <DataTable loading={loading} dataSource={reports} columns={columns} />;
});

ReportsTable.displayName = 'ReportsTable';
