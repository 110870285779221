import React, { FocusEvent, useRef, useState } from 'react';
import { Button, Form, Input, Popconfirm } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../theme';
import { Space } from '../Space';
import { UploadButton } from '../UploadButton';
import { getGCPKeyfile, getKeyfileProjectId, normalizeFile } from '../../utils/files';
import { hyphenateString } from '../../utils/string';
import { ReactComponent as ArrowIcon } from '../../assets/icons/chevron-double-right.svg';
import { IDFAgentValues } from '../../pages/AI/Automation/NLUProviders/types';
import { ReactComponent as Trash } from '../../assets/icons/trash-alt.svg';
import { getFieldName, getFieldPath } from './utils';

export const GoogleCloudProjectInput = ({
  initialValues,
  isEditing,
  isProjectCreation = false,
  isDLP,
}: {
  initialValues?: IDFAgentValues;
  isEditing?: boolean;
  isProjectCreation?: boolean;
  isDLP?: boolean;
}): JSX.Element => {
  const { t } = useTranslation('project_settings');
  const { validateFields, setFieldValue, getFieldValue } = Form.useFormInstance();
  const [popupVisible, setPopupVisible] = useState(false);
  const [keyfileProjectId, setKeyfileProjectId] = useState('');
  const projectNameTimeout = useRef<NodeJS.Timeout>();
  const isFulfillmentAuthKeyfile = !!initialValues?.gcp_fulfillment_auth_keyfile;

  const checkProjectName = async (projectName: string, keyfile: UploadFile[]) => {
    if (projectName && keyfile) {
      const result = await getGCPKeyfile(keyfile);
      const projectId = result && getKeyfileProjectId(result);
      if (projectId) {
        setPopupVisible(projectId !== projectName);
        setKeyfileProjectId(projectId);
      }
    }
    if (isEditing && initialValues?.gcp_keyfile) {
      const result = JSON.parse(initialValues.gcp_keyfile);
      const projectId = result.project_id;
      setPopupVisible(projectId !== projectName);
      setKeyfileProjectId(projectId);
    }
  };

  const handleProjectIdBlur = (event: FocusEvent<HTMLInputElement>) =>
    setFieldValue(
      getFieldPath('gcp_project_name', isProjectCreation),
      hyphenateString(event.target.value),
    );

  const handleProjectIdChange = () => {
    if (projectNameTimeout.current) {
      clearTimeout(projectNameTimeout.current);
    }
    projectNameTimeout.current = setTimeout(async () => {
      await checkProjectName(
        getFieldValue(getFieldPath('gcp_project_name', isProjectCreation)),
        getFieldValue(getFieldPath('gcp_keyfile', isProjectCreation)),
      );
    }, 500);
  };

  const confirm = () => setPopupVisible(false);

  const handleProjectNameCancel = () => {
    setFieldValue(getFieldPath('gcp_project_name', isProjectCreation), keyfileProjectId);
    setPopupVisible(false);
  };

  const handleKeyfileChange = async () => {
    try {
      await validateFields([getFieldPath('gcp_keyfile', isProjectCreation)]);
    } catch {
      setKeyfileProjectId('');
      setPopupVisible(false);
    }
    await checkProjectName(
      getFieldValue(getFieldPath('gcp_project_name', isProjectCreation)),
      getFieldValue(getFieldPath('gcp_keyfile', isProjectCreation)),
    );
  };

  return (
    <>
      <Space>
        {!isDLP && (
          <Form.Item
            name={getFieldPath('display_name', isProjectCreation)}
            label={t('agent_name')}
            className={formItemClass.base}
            rules={[{ required: true }]}
          >
            <Input placeholder={t('agent_name')} />
          </Form.Item>
        )}
        <Popconfirm
          title={t('project_id_error')}
          onConfirm={confirm}
          onCancel={handleProjectNameCancel}
          placement="right"
          open={popupVisible}
          okText={t('yes')}
          okButtonProps={{ icon: <ArrowIcon width={14} height={14} /> }}
          cancelText={t('use_id_keyfile')}
          icon={<></>}
        >
          <Form.Item
            name={getFieldPath('gcp_project_name', isProjectCreation)}
            label={t('gcp_project_id')}
            tooltip={t('gcp_project_id_tooltip')}
            className={formItemClass.base}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={t('gcp_project_id')}
              onBlur={handleProjectIdBlur}
              onChange={handleProjectIdChange}
            />
          </Form.Item>
        </Popconfirm>
      </Space>
      <Space>
        {!isEditing && (
          <Form.Item
            name={getFieldPath('gcp_keyfile', isProjectCreation)}
            label={t('gcp_keyfile')}
            tooltip={t('gcp_keyfile_tooltip')}
            className={formItemClass.base}
            valuePropName="fileList"
            getValueFromEvent={normalizeFile}
            rules={[{ required: true }]}
          >
            <UploadButton accept=".json" onChange={handleKeyfileChange} />
          </Form.Item>
        )}
        {!isDLP && (
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues[getFieldName('gcp_fulfillment_auth_keyfile', isProjectCreation)] !==
              currentValues[getFieldName('gcp_fulfillment_auth_keyfile', isProjectCreation)]
            }
          >
            {({ getFieldValue }) => {
              return !isProjectCreation &&
                isFulfillmentAuthKeyfile &&
                getFieldValue('gcp_fulfillment_auth_keyfile') ===
                  initialValues.gcp_fulfillment_auth_keyfile ? (
                <Form.Item
                  label={t('gcp_fulfillment_file')}
                  tooltip={t('gcp_fulfillment_file_tooltip')}
                  className={formItemClass.base}
                >
                  <Button
                    type="default"
                    icon={<Trash width={13} height={14} />}
                    onClick={() => setFieldValue('gcp_fulfillment_auth_keyfile', null)}
                  >
                    {t('ai:remove_file')}
                  </Button>
                </Form.Item>
              ) : (
                <Form.Item
                  name={getFieldPath('gcp_fulfillment_auth_keyfile', isProjectCreation)}
                  label={t('gcp_fulfillment_file')}
                  tooltip={t('gcp_fulfillment_file_tooltip')}
                  className={formItemClass.base}
                  valuePropName="fileList"
                  getValueFromEvent={normalizeFile}
                >
                  <UploadButton accept=".json" />
                </Form.Item>
              );
            }}
          </Form.Item>
        )}
      </Space>
    </>
  );
};
