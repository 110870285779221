import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntegrations } from '../../state/integrations';
import { CenteredSpinner, OverlaySpinner } from '../../components/Spinner';
import useStateLoading from '../../hooks/useStateLoading';
import { NoProject } from '../../components/NoProject';

export const OldKnowledge: React.FC = () => {
  const { t } = useTranslation('pages_title');
  const { oldKnowledge } = useIntegrations();
  const { isStateLoading, isProject } = useStateLoading();
  const [isIframeLoading, setIframeLoading] = useState<boolean>(true);
  const url = oldKnowledge && oldKnowledge[0].urls.url;

  useEffect(() => setIframeLoading(true), [url]);

  if (isStateLoading) return <CenteredSpinner isOverlay={true} isVisible={isStateLoading} />;
  if (!isProject) return <NoProject />;
  return (
    <>
      {oldKnowledge && (
        <iframe
          title={t('knowledge').toLowerCase()}
          src={url}
          onLoad={() => setIframeLoading(false)}
        />
      )}
      <OverlaySpinner loading={isIframeLoading} />
    </>
  );
};
