import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { CenteredSpinner } from '../../../../components/Spinner';
import { AnalyticsService } from '../../../../services/analytics';
import { useIntegrations } from '../../../../state/integrations';
import { getEmbedType, initializeEmbedSDK } from '../../../../utils/looker';
import { useAnalyticsData } from '../../../../hooks/useAnalyticsData';

export const InsightsIframe: React.FC = () => {
  const { analytics: { url, externalId } = {} } = useIntegrations();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const { data } = useAnalyticsData({
    queryKey: AnalyticsService.queryKeyDashboards,
    fetchDataFn: AnalyticsService.getDashboards,
  });

  useEffect(() => {
    const { id, component } = data?.find(e => e.name === 'Insights') || {};
    if (id && component && externalId && url) {
      setIsLoading(true);
      const container = document.getElementById('dashboard-container');
      if (container?.firstChild) container.removeChild(container.firstChild);

      initializeEmbedSDK(externalId, component.id, url);

      const embedType = getEmbedType('dashboards', id.toString());
      if (embedType && container) {
        embedType()
          .withAllowAttr('fullscreen')
          .appendTo(container)
          .build()
          .connect()
          .then(() => setIsLoading(false))
          .catch(error => {
            setIsLoading(false);
            console.error(`Error embedding dashboards: `, error);
          });
      }
    }
  }, [data, externalId]);

  return (
    <>
      <DashboardContainer id="dashboard-container" />
      <CenteredSpinner isOverlay={true} isVisible={isLoading} />
    </>
  );
};

const DashboardContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  iframe {
    height: 100%;
  }
`;
