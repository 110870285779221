import { useEffect, useMemo } from 'react';
import { useMatches, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Paths } from '../../types/paths';

export const useRouteFlagRedirect = (): void => {
  const matches = useMatches();
  const navigate = useNavigate();
  const flags = useFlags();

  const matchingRoute = useMemo(() => {
    const reversedMatches = [...matches].reverse();
    const exactMatch = reversedMatches
      .map(match => {
        const path = match.pathname;
        return Object.values(Paths).find(route => path === route() && route.flag);
      })
      .find(Boolean);
    if (exactMatch) return exactMatch;

    return reversedMatches
      .map(match => {
        const path = match.pathname;
        return Object.values(Paths).find(route => path.startsWith(route()) && route.flag);
      })
      .find(Boolean);
  }, [matches]);

  const flagForRoute = matchingRoute?.flag;

  useEffect(() => {
    if (flagForRoute && flags[flagForRoute] === false) {
      navigate('/');
    }
  }, [navigate, flags, flagForRoute]);
};
