import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Content } from '../../../../components/Content';
import { StyledCard, CardInfo } from '../../AI.styles';
import { Paths } from '../../../../types/paths';
import { ViewLink } from '../../../../components/ViewLink';
import { useAccount } from '../../../../state/account';
import { CenteredSpinner } from '../../../../components/Spinner';
import useProjectLoading from '../../../../hooks/useProjectLoading';

export const ConfigurationList: React.FC = () => {
  const { t } = useTranslation('ai');
  const { isHumanbotProject } = useAccount();
  const { newDesign } = useFlags();
  const isProjectLoading = useProjectLoading();

  return (
    <Content imgBg={false}>
      <Row gutter={16}>
        {newDesign && (
          <Col xs={24} sm={12} lg={8} xxl={6}>
            <StyledCard title={t('design_va')} bordered={false}>
              <CardInfo>{t('design_va_exp')}</CardInfo>
              <ViewLink to={Paths.aiDesign()} />
            </StyledCard>
          </Col>
        )}
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard title={t('fallback_behav')} bordered={false}>
            <CardInfo>{t('def_cust_exp')}</CardInfo>
            <ViewLink to={Paths.aiFallbackBehaviour()} />
          </StyledCard>
        </Col>
        {!isHumanbotProject && (
          <Col xs={24} sm={12} lg={8} xxl={6}>
            <StyledCard title={t('knowledge_bases')} bordered={false}>
              <CardInfo>{t('knowledge_bases_description')}</CardInfo>
              <ViewLink to={Paths.aiKnowledgeBases()} />
            </StyledCard>
          </Col>
        )}
      </Row>
      <CenteredSpinner isOverlay={true} isVisible={isProjectLoading} />
    </Content>
  );
};
