import React, { createContext, useContext, useMemo, useState } from 'react';
import { FormError } from '../utils/errors';

export interface IFormContext {
  errorFields: FormError[];
  setErrorFields: (errorFields: FormError[]) => void;
}
const FormContext = createContext<IFormContext>({} as IFormContext);

export const useFormContext = () => useContext(FormContext);

export const FormProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [errorFields, setErrorFields] = useState<FormError[]>([]);

  const contextValue = useMemo(() => {
    return { errorFields, setErrorFields };
  }, [errorFields, setErrorFields]);

  return <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>;
};
