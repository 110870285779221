import { Select, SelectProps } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RoutingMethod } from '../../pages/AI/Routing/Routers/types';
import { useAccount } from '../../state/account';

export const RoutingMethodSelector: React.FC<SelectProps> = props => {
  const { isHumanbotProject } = useAccount();
  const { genesysRouter, avayaRouter, livechatRouter } = useFlags();
  let routingMethods: RoutingMethod[] = ['task_router', 'direct_dial'];

  if (genesysRouter) routingMethods = [...routingMethods, 'genesys'];
  if (avayaRouter) routingMethods = [...routingMethods, 'avaya'];
  if (livechatRouter) routingMethods = [...routingMethods, 'livechat'];

  if (isHumanbotProject) {
    routingMethods = ['direct_dial'];
  }

  return (
    <Select {...props}>
      {routingMethods.map(item => (
        <Select.Option key={item} value={item}>
          {item}
        </Select.Option>
      ))}
    </Select>
  );
};
