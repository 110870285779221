import React from 'react';
import { css, useTheme, Global } from '@emotion/react';
import { formItemClass, ThemeType } from './theme';
import AtypTextRegularEot from './assets/fonts/AtypBLText-Regular.eot';
import AtypTextRegularWoff2 from './assets/fonts/AtypBLText-Regular.woff2';
import AtypTextRegularWoff from './assets/fonts/AtypBLText-Regular.woff';
import AtypTextSemiboldEot from './assets/fonts/AtypBLText-Semibold.eot';
import AtypTextSemiboldWoff2 from './assets/fonts/AtypBLText-Semibold.woff2';
import AtypTextSemiboldWoff from './assets/fonts/AtypBLText-Semibold.woff';
import AtypDisplaySemiboldEot from './assets/fonts/AtypBLDisplay-Semibold.eot';
import AtypDisplaySemiboldWoff2 from './assets/fonts/AtypBLDisplay-Semibold.woff2';
import AtypDisplaySemiboldWoff from './assets/fonts/AtypBLDisplay-Semibold.woff';

const makeGlobalStyles = (theme: ThemeType) => css`
  @font-face {
    font-family: 'Atyp BL Text';
    src: url(${AtypTextRegularEot});
    src: url('${AtypTextRegularEot}?#iefix') format('embedded-opentype'),
      url(${AtypTextRegularWoff2}) format('woff2'), url(${AtypTextRegularWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Atyp BL Text';
    src: url(${AtypTextSemiboldEot});
    src: url('${AtypTextSemiboldEot}?#iefix') format('embedded-opentype'),
      url(${AtypTextSemiboldWoff2}) format('woff2'), url(${AtypTextSemiboldWoff}) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Atyp BL Display';
    src: url(${AtypDisplaySemiboldEot});
    src: url('${AtypDisplaySemiboldEot}?#iefix') format('embedded-opentype'),
      url(${AtypDisplaySemiboldWoff2}) format('woff2'),
      url(${AtypDisplaySemiboldWoff}) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    -webkit-text-size-adjust: 100%;
  }
  /* variables */
  html {
    --blue-color: #10006b;
    --yellow-color: #f4d70e;
  }

  /* Normalize */
  body {
    margin: 0;
    padding: 0;
  }
  a {
    background-color: transparent;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    color: ${theme.primaryColor};
    &:hover,
    &:active {
      color: ${theme.primaryColor};
    }
  }
  small {
    font-size: 80%;
  }
  img {
    border-style: none;
    vertical-align: middle;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
    cursor: pointer;
  }
  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  button:-moz-focusring,
  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  textarea {
    overflow: auto;
  }
  [type='checkbox'],
  [type='radio'] {
    box-sizing: border-box;
    padding: 0;
  }
  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  [hidden] {
    display: none;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  ::selection {
    background: #10006b;
    color: #fff;
  }
  body {
    font-family: ${theme.fontFamilyBase};
    font-size: ${theme.typography.body.fontSize};
    line-height: ${theme.typography.body.lineHeight};
    font-feature-settings: 'tnum', 'tnum';
    font-variant: tabular-nums;
  }
  // Removed blue autofill color
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.primaryColor};
    margin-top: 0;
  }
  h1 {
    font-size: ${theme.typography.h1.fontSize};
    font-weight: 600;
    line-height: ${theme.typography.h1.lineHeight};
    font-family: ${theme.fontFamilyAccent};
    text-transform: uppercase;
    margin: 0 0 0.8em;
    color: ${theme.primaryColor};
  }
  h2 {
    font-size: ${theme.typography.h2.fontSize};
    font-weight: 400;
    line-height: ${theme.typography.h2.lineHeight};
    font-family: ${theme.fontFamilyBase};
    letter-spacing: -0.5px;
    margin: 0 0 0.8em;
  }
  p {
    margin-top: 0;
  }
  svg {
    fill: currentColor;
  }
  #root {
    min-height: 100%;
  }
  .ant-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    font-family: ${theme.fontFamilyAccent};
    text-transform: uppercase;

    span + svg {
      margin-left: 8px;
    }
  }
  .ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 6px;
  }
  .ant-btn.ant-btn-loading::before {
    display: none;
  }
  .ant-btn:not(:disabled):not(.ant-btn-disabled):not(.ant-upload-list-item-action):hover,
  .ant-btn:focus {
    background-color: ${theme.primaryColor};
    color: #fff;
    .ant-spin-spinning {
      color: #fff;
    }
  }
  .ant-modal.modal-danger .ant-input {
    border-color: ${theme.tableHeaderBorderColor};
    background-color: ${theme.bgColor};
  }
  .ant-modal.modal-danger .ant-input-search-button {
    border: 1px solid ${theme.tableHeaderBorderColor};
    border-left: none;
    background-color: ${theme.bgColor};
  }

  .menu-select .ant-select-arrow {
    display: none;
  }

  .ant-form-item-tooltip.anticon-question-circle {
    width: 14px;
    height: 14px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23bdbdc1' d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z'/%3E%3C/svg%3E");
    svg {
      display: none;
    }
  }
  /** Tag **/
  .ant-tag {
    margin-top: 2px;
    margin-bottom: 2px;
    font-weight: 600;
    line-height: 22px;
    text-transform: uppercase;
  }
  .ant-tag .anticon-close path {
    fill: ${theme.primaryColor};
  }
  .ant-tag-draft {
    border-color: ${theme.draftColor};
    color: ${theme.draftColor};
    background-color: ${theme.draftBGColor};
  }

  .presentation-option-tag {
    background-color: ${theme.lightBg};
    display: flex;
  }
  .presentation-option-tag .anticon-close {
    margin-top: 0 !important;
  }
  .ant-form-item-label > label .ant-form-item-tooltip {
    color: ${theme.secondaryBg};
  }
  .ant-form-vertical .ant-form-item-label {
    font-weight: 600;
  }
  .ant-dropdown .ant-dropdown-menu {
    padding: 4px 0;
  }
  .ant-dropdown-menu-title-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    > div {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
    font-weight: 600;
  }
  .ant-menu-submenu-popup .ant-menu-vertical .ant-menu-item {
    padding-inline: 20px 36px;
    font-weight: 600;
  }
  .ant-dropdown-menu-item-group-list {
    margin: 0;
  }
  .ant-menu-item-group-title,
  .ant-dropdown-menu-item-group-title {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .ant-dropdown-menu-item-group-title {
    padding: 8px 16px;
  }
  .ant-menu > .ant-menu-item-divider,
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-divider {
    margin: 4px 0 0;
  }
  /** Menu **/
  .ant-menu-horizontal {
    position: relative;
    margin: 0 40px;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: -40px;
      right: -40px;
      height: 100%;
      background-color: inherit;
      box-shadow: 0 1px 0 0 ${theme.secondaryBg};
    }
    @media (max-width: 1024px) {
      margin: 0 15px;
      &::before {
        left: -15px;
        right: -15px;
      }
    }
  }
  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    display: none;
  }
  .ant-menu .ant-menu-submenu-title .ant-menu-item-icon,
  .ant-menu .ant-menu-item .ant-menu-item-icon {
    vertical-align: -0.18em;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 0;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-submenu-title {
    padding: ${theme.menuItemPadding};
  }
  .ant-menu .ant-menu-item-divider {
    border-top-width: 1px;
  }
  .ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub {
    padding: 4px 0;
  }
  .ant-menu-title-content > .submenu-item {
    font-weight: 400;
  }
  .sub-menu-item-active {
    background-color: ${theme.primaryColor} !important;
    a,
    span {
      color: #ffffff;
      font-weight: 600 !important;
    }
    &:hover {
      background-color: ${theme.primaryColor};
      a,
      span {
        color: #ffffff;
        font-weight: 600 !important;
      }
    }
  }
  .no-pointer-events {
    pointer-events: none;
  }

  /** Slider **/
  .ant-slider {
    margin-top: 24px;
  }
  .ant-slider-tooltip {
    z-index: 1;
    inset-block-start: -26px !important;
  }
  .ant-slider-tooltip .ant-tooltip-inner {
    min-width: 30px;
    min-height: 20px;
    padding: 0 4px;
    background-color: ${theme.primaryColor};
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    text-align: center;
  }
  .ant-slider.ant-slider-disabled .ant-slider-handle::after {
    background-color: ${theme.secondaryBg};
    box-shadow: 0 0 0 2px ${theme.secondaryBg};
  }
  .ant-slider-tooltip .ant-tooltip-arrow {
    display: none;
    height: 0;
    overflow: hidden;
  }

  /** Switch **/
  .ant-switch {
    min-width: 36px;

    .ant-switch-handle {
      width: 20px;
      height: 20px;
      top: 3px;
      inset-inline-start: 3px;
      &::before {
        box-shadow: none;
      }
    }

    &.ant-switch-checked .ant-switch-handle {
      inset-inline-start: calc(100% - 23px);
    }
  }
  /** Pagination **/
  .ant-pagination .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination .ant-pagination-next .ant-pagination-item-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .ant-pagination .ant-pagination-total-text {
    color: ${theme.textColor};
  }
  /** Popconfirm **/
  .ant-popconfirm .ant-popconfirm-message {
    margin-bottom: 16px;
  }
  .ant-popconfirm .ant-popconfirm-buttons button {
    margin-inline-start: 8px;
  }
  /** Alert **/
  .ant-alert-icon {
    flex: 0 0 auto;
  }
  /** Modal **/
  .ant-modal .ant-modal-title {
    text-transform: uppercase;
  }
  .modal-danger .ant-modal-title {
    color: ${theme.errorColor};
  }
  .ant-modal .ant-modal-close {
    display: none;
  }
  .ant-modal-header {
    padding: 24px 24px 0;
  }
  .ant-modal-body {
    min-height: 56px;
    padding: 0 24px;
    margin: 16px 0 24px;
    img {
      max-width: 100%;
    }
  }
  .ant-modal-footer {
    padding: 0 24px 14px;
  }
  /** Dropdown **/
  .ant-dropdown .ant-table-filter-dropdown {
    background-color: #fff;
  }
  .ant-dropdown .ant-table-filter-dropdown {
    .ant-table-filter-dropdown-search {
      padding: 8px;
    }
    .ant-table-filter-dropdown-btns {
      padding: 7px 8px;
    }
  }
  // Table dropdowns
  .filter-search {
    padding: 8px;
  }
  .filter-search-input {
    margin-bottom: 8px;
  }
  .ant-table-filter-dropdown-btns,
  .filter-search-btns {
    button + button {
      margin-left: 8px;
    }
    .ant-btn:not(.ant-btn-primary) {
      padding: 0;
    }
    .ant-btn:disabled:not(.ant-btn-primary) {
      color: ${theme.textColorDisabled};
    }
    .ant-btn:not(:disabled):not(.ant-btn-primary),
    .ant-btn:not(:disabled):not(.ant-btn-primary):hover,
    .ant-btn:not(:disabled):not(.ant-btn-primary):focus {
      color: ${theme.linkColor};
      background-color: transparent;
    }
  }
  .filter-search-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ant-table-filter-dropdown .ant-picker-range-separator {
    margin-right: 10px;
  }
  .ant-dropdown {
    .ant-table-filter-dropdown {
      .ant-dropdown-menu {
        padding: 5px 0;
        background-color: #fff;
      }
      .ant-dropdown-menu-item,
      .ant-dropdown-menu-item-selected,
      .ant-dropdown-menu-submenu-title-selected {
        font-weight: 400;
        color: ${theme.textColor};
        background-color: transparent !important;
      }
    }
  }
  .ant-table-filter-dropdown-btns:not(.custome-filter-search)
    .ant-btn.ant-btn-primary
    > span::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 10px;
    margin-right: 2px;
    mask-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+');
    mask-size: auto 10px;
    mask-repeat: no-repeat;
    background-color: ${theme.primaryColor};
    transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-table-filter-dropdown-btns .ant-btn.ant-btn-primary:hover > span::before {
    background-color: #fff;
  }

  /** Steps **/
  .ant-steps-item-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    background-color: #ffffff;
  }
  .ant-steps-icon {
    color: ${theme.primaryColor};
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-right: 40px;
    padding-left: 20px;
  }
  .ant-steps .ant-steps-item {
    flex: 0 1 auto;
  }
  .ant-steps-finish-icon {
    color: ${theme.successColor};
  }
  .ant-steps .ant-steps-item-wait .ant-steps-item-icon,
  .ant-steps .ant-steps-item-finish .ant-steps-item-icon,
  .ant-steps .ant-steps-item-process .ant-steps-item-icon {
    border-color: ${theme.secondaryBg};
  }
  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after,
  .ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: ${theme.secondaryBg};
  }

  /** Form Items **/
  .ant-form {
    max-width: ${theme.formWidth};
  }
  .ant-form-item {
    max-width: 100%;
    .ant-input-number,
    .ant-input-number-affix-wrapper {
      width: 100%;
    }
  }
  .${formItemClass.base} {
    width: 360px;
  }
  .${formItemClass.small} {
    width: 304px;
  }
  .${formItemClass.xsmall} {
    width: 160px;
  }
  .${formItemClass.full} {
    width: ${theme.formWidth};
  }
  .last-form-space {
    .ant-space-item:last-of-type .ant-form-item {
      margin-bottom: 0;
    }
    .ant-space-item:first-of-type .ant-form-item {
      @media (min-width: 886px) {
        margin-bottom: 0;
      }
    }
  }
  .has-error-shift .ant-form-item-explain-error {
    margin-bottom: -10px;
  }
  .ant-select-dropdown {
    padding: 5px 0;
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: #fff;
    }
  }
  .ant-form .ant-radio-button-wrapper {
    min-width: 47px;
    text-align: center;
  }
  .ant-form .ant-radio-button-wrapper-checked {
    outline: 1px solid ${theme.primaryColor};
  }
  .ant-form .ant-radio-button-wrapper-disabled {
    outline: 1px solid ${theme.inputPlaceholderColor};
  }
  .ant-form .ant-radio-button-wrapper-in-form-item:not(.ant-radio-button-wrapper-disabled) {
    outline: 1px solid ${theme.textColor};
  }
  .ant-form .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0;
  }
  .ant-input[disabled],
  .ant-input[disabled]:hover,
  .ant-picker.ant-picker-disabled,
  .ant-input-number-disabled,
  .ant-input-number-disabled:hover:not([disabled]),
  .ant-input-number-affix-wrapper-disabled,
  .ant-input-number-affix-wrapper-disabled:hover:not([disabled]),
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${theme.lightBg};
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-arrow {
    color: ${theme.inputPlaceholderColor};
  }
  .ant-input-password-icon {
    color: ${theme.primaryColor};
  }
  .ant-form-item-explain,
  .ant-form-item-extra {
    & > div {
      padding-top: 2px;
      font-size: 12px;
    }
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .ant-form-item-explain {
    font-weight: 600;
  }
  .ant-form-item .ant-form-item-extra {
    min-height: auto;
    margin-top: 4px;
  }
  .ant-color-picker-trigger {
    width: 100%;
    justify-content: flex-start;
  }
  .ant-select-multiple .ant-select-selection-item {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: ${theme.primaryColor};
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-tag {
    border-color: #ffffff;
    color: #ffffff;
    background-color: transparent;
  }

  // Datepicker
  .ant-picker {
    width: 100%;
  }
  .ant-picker-dropdown .ant-picker-decade-panel,
  .ant-picker-dropdown .ant-picker-date-panel .ant-picker-content,
  .ant-picker-dropdown .ant-picker-date-panel,
  .ant-picker-dropdown .ant-picker-year-panel,
  .ant-picker-dropdown .ant-picker-month-panel,
  .ant-picker-dropdown .ant-picker-week-panel {
    width: 312px;
  }
  .ant-picker-panels {
    padding: 8px 16px;
  }
  .ant-picker-date-panel {
    padding: 8px 0;
  }

  .ant-picker-header-super-prev-btn {
    margin-left: 8px;
  }
  .ant-picker-header-super-next-btn {
    margin-right: 8px;
  }
  .ant-picker-range-wrapper .ant-picker-date-panel {
    padding: 0;
  }
  .ant-picker-range-wrapper .ant-picker-header-super-prev-btn,
  .ant-picker-range-wrapper .ant-picker-header-super-next-btn {
    margin: 0;
  }

  .ant-picker-panel + .ant-picker-panel {
    margin-left: 16px;
  }
  .ant-picker-dropdown .ant-picker-date-panel .ant-picker-content th {
    width: 36px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${theme.inputPlaceholderColor};
  }
  .ant-picker-dropdown .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
  .ant-picker-dropdown .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
    inset-inline-start: 24px;
  }
  .ant-picker-dropdown .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
  .ant-picker-dropdown .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
    inset-inline-end: 24px;
  }
  .ant-picker-dropdown .ant-picker-header {
    padding: 0 0 8px;
    button {
      text-transform: uppercase;
    }
    & > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      &:hover {
        background: ${theme.lightBg};
      }
    }
  }
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: ${theme.lightBg};
  }
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
  .ant-picker-dropdown
    .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after,
  .ant-picker-dropdown
    .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after {
    background: ${theme.secondaryBg};
  }
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
  .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-color: ${theme.primaryColor};
  }

  //TimePicker
  .ant-picker-dropdown .ant-picker-ranges {
    padding: 8px;
    margin: 0;
  }
  .ant-picker-panel-container .ant-picker-content {
    padding-top: 8px;
    padding-bottom: 4px;
  }
  .ant-picker-dropdown
    .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner,
  .ant-picker-dropdown
    .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner:hover {
    background: ${theme.primaryColor};
    color: #ffffff;
  }
  .ant-picker:not(.ant-picker-disabled):hover {
    border-color: ${theme.primaryColor};
  }

  //Upload
  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
    width: 116px;
    height: 116px;
    margin-bottom: 0;
  }
  .ant-upload.ant-upload-select img {
    max-height: 100%;
  }
  .ant-upload.ant-upload-select {
    color: ${theme.primaryColor};
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  /** Checkbox **/
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    :not(.ant-checkbox-indeterminate)
    .ant-checkbox-inner,
  .ant-checkbox:not(.ant-checkbox-disabled):not(.ant-checkbox-indeterminate):hover
    .ant-checkbox-inner {
    border-color: ${theme.primaryColor};
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner,
  .ant-checkbox-indeterminate:hover .ant-checkbox-inner {
    background-color: ${theme.secondaryColor};
    border-color: ${theme.secondaryColor};
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    height: 2px;
    background-color: ${theme.primaryColor};
  }

  /** Notification **/
  .ant-notification .ant-notification-notice {
    &:first-letter {
      text-transform: capitalize;
    }
    padding: 16px;
    border-radius: 0;
    .ant-notification-notice-icon svg {
      width: 20px;
      height: 20px;
    }
    .ant-notification-notice-with-icon .ant-notification-notice-message {
      margin: 0 32px;
      font-size: ${theme.typography.body.fontSize};
      line-height: 1.3;
      color: ${theme.textColor};
    }
    .ant-notification-notice-with-icon .ant-notification-notice-description {
      margin-left: 34px;
    }
    .ant-notification-notice-close {
      width: 20px;
      height: 20px;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      color: ${theme.textColor};
      &:hover {
        background: none;
      }
    }
    &.ant-notification-notice-success {
      background-color: #e8f7e8;
      border: 1px solid ${theme.successColor};
    }
    &.ant-notification-notice-error {
      background-color: #ffebeb;
      border: 1px solid ${theme.errorColor};
    }
    &.ant-notification-notice-warning {
      background-color: #fdfbe6;
      border: 1px solid #f4d70e;
    }
  }
  .ant-notification-notice-icon {
    top: 50%;
    transform: translateY(-50%);
  }

  /** Tabs **/
  .ant-tabs-nav {
    text-transform: uppercase;
  }
  .ant-tabs-tab-active {
    font-weight: 600;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: none;
  }

  /** Card **/
  .ant-card {
    padding: 26px 18px 18px;
    margin-bottom: 16px;
  }
  .ant-card-head {
    text-transform: uppercase;
  }
  .ant-card-head-wrapper {
    margin-bottom: 12px;
  }
  .ant-card-title {
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    padding-bottom: 20px;
    text-transform: uppercase;
  }
  div:has(> canvas) {
    margin: 20px 0;
  }
  .g2-html-annotation {
    font-size: 26px !important;
    @media (max-width: 1800px) {
      font-size: 18px !important;
    }
    @media (max-width: 1300px) {
      font-size: 16px !important;
    }
  }
  .dot {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
  }
  .ant-popover-content {
    max-width: 340px;
  }
  mark {
    background-color: ${theme.markColor};
    padding-left: 0;
    padding-right: 0;
  }
  .rc-virtual-list-scrollbar {
    width: 4px !important;
  }
  .rc-virtual-list-scrollbar-show {
    background: ${theme.secondaryBg};
  }
  .rc-virtual-list-scrollbar-thumb {
    background: #786fad !important;
    border-radius: 0 !important;
  }
  .ant-empty-graph {
    background: ${theme.bgColor};
    margin: 0;
    padding: 140px 0;
  }

  /** Collapse **/
  .ant-collapse-header-text {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const GlobalStyles = (): JSX.Element => {
  const theme = useTheme();
  return <Global styles={makeGlobalStyles(theme)} />;
};
